.forgetPasswordScreenWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: #000;
  font-family: "Satoshi";

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
  }
}

.forgetPasswordCtaContainer {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-top: 50px;

  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.5px;
  }
}

.forgetPasswordScreenContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.forgetPasswordTextInputContainer {
  display: flex;
  flex-direction: column;
}

.forgetPasswordTextInputContainer:not(:last-of-type) {
  margin-bottom: 40px;
}

.forgetPasswordScreenContainer label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}

.passwordInput {
  flex: 1;
}

.passwordToggeWrapper {
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}

.passwordToggleIconWrapper {
  position: absolute;
  right: 10px;
  display: flex;
}

.forgetPasswordforgetPasswordButton {
  background-color: #008135;
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  width: 350px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.forgetPasswordforgetPasswordButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  .forgetPasswordButton {
    border: none;
    background: transparent;
    color: #008135;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }

  span {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
  }
}

.forgetPasswordforgetPasswordTextContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 14px;

  button {
    font-size: 12px;
  }
}

.forgetPasswordButtonContainer {
  margin-top: 40px;
}

.forgetPasswordSignupTextContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;

  button {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }
}

.resetPasswordForm {
  margin-top: 50px;
}
