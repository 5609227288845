select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

ul {
  list-style: none;
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: #000;

  &:hover {
    text-decoration: none;
    text-decoration: none;
    display: inline-block;
    color: #000;
  }
}

img {
  display: block;
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
