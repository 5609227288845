.connectScreenWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.servicesContainer {
  button {
    display: flex;
    width: 350px;
    height: 46px;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #bfbfbf;
    background: transparent;
    margin-bottom: 30px;
  }
}

.servicesHeader {
  svg {
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  p {
    color: #595959;
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.startplayingButton {
  background-color: #008135;
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  width: 350px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 50px;
}
