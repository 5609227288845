@import "styles/partials/main.scss";

.signupScreenWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  color: #000;
  font-family: "Satoshi";
  position: relative;

  h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
  }
}

.signupCtaContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  h5 {
    margin-top: 20px;
  }
}

.signupScreenContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.signupTextInputContainer {
  display: flex;
  flex-direction: column;
}

.signupTextInputContainer:not(:last-of-type) {
  margin-bottom: 40px;
}

.signupScreenContainer label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}

.passwordInput {
  flex: 1;
}

.passwordToggeWrapper {
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}

.passwordToggleIconWrapper {
  position: absolute;
  right: 10px;
  display: flex;
}

.signupSignupButton {
  background-color: $primary-green;
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  width: 350px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.signupLoginButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  .loginButton {
    border: none;
    background: transparent;
    color: #008135;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }

  span {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
  }
}

.signupButtonContainer {
  margin-top: 40px;
}

.termsAndCondition {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%); /* Centers the element horizontally */
  width: 100%;
  
  p {
    text-align: center;
    color: #595959;
    font-size: 14px;
    font-weight: 500;
  }

  .termsAndConditionFlex{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 10px;

    a{
      text-align: center;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      color: #008135;
    }
  }
}
