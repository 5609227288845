.resetPasswordScreenWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: #000;
  font-family: "Satoshi";

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-weight: 400;
  }
}

.resetPasswordCtaContainer {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-top: 50px;

  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 38px;
    letter-spacing: -0.5px;
  }
}

.resetPasswordScreenContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.resetPasswordTextInputContainer {
  display: flex;
  gap: 10px;
}

.resetPasswordTextInputContainer:not(:last-of-type) {
  margin-bottom: 40px;
}

.resetPasswordScreenContainer label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}

.passwordToggeWrapper {
  // border: 1px solid #bfbfbf;
  // padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}

.passwordToggleIconWrapper {
  position: absolute;
  right: 10px;
  display: flex;
}

.resetPasswordresetPasswordButton {
  background-color: #008135;
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  width: 350px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
}

.resetPasswordresetPasswordButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  .resetPasswordButton {
    border: none;
    background: transparent;
    color: #008135;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
  }

  span {
    color: #595959;
    font-size: 14px;
    font-weight: 400;
  }
}

.resetPasswordresetPasswordTextContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 14px;

  button {
    font-size: 12px;
  }
}

.resetPasswordButtonContainer {
  margin-top: 40px;
}

.resetPasswordSignupTextContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;

  button {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }
}

.resetPasswordForm {
  margin-top: 50px;
}

.loginSignupTextContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;

  button {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }
}

.otpFormContainer {
  margin-top: 30px;
}
