.profilescreenWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh;
  // padding-top: 2rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
}

.profileSettingsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.shareProfileContainer {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: #fafafa;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  width: 110px;
  padding: 4px 0px;
  position: absolute;
  right: 20px;
  top: -3px;
  display: none;

  p {
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }
}

.showProfileContainer {
  display: block;
}

.hideProfileContainer {
  display: none;
}
