.onboardingWrapper {
  height: 100vh;
  background: linear-gradient(
    346deg,
    #0f2f40 24.15%,
    rgba(255, 182, 24, 0.15) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 1;
  padding: 0 2rem;

  img {
    width: 100%;
  }

  h5 {
    font-family: "Satoshi", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.75px;
    color: #fff;
  }

  p {
    color: #fff;
    text-align: center;
    font-family: "Satoshi", sans-serif;
    font-weight: 200;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: green;
  opacity: 0.6;
  z-index: -1;
}

.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 1px solid #008135;
    background: #fafafa;
    cursor: pointer;
    color: #008135;
    flex: 1;
    height: 48px;
    padding: 8px 16px;
  }
}
