.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 72px;
  padding: 0;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #02bd71;
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -0.75px;
}

.otp-input:active,
.otp-input:focus {
  outline: none;
  border: 1px solid #02bd71;
}
