.navContainer {
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.profileContainer {
  z-index: 100;
  h5 {
    font-weight: 100;
    z-index: 100;
  }

  p {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: capitalize;
    z-index: 100;
  }
}

.notificationContainer {
  display: flex;
  align-items: baseline;
}
